<template>
  <div id="vue">
    <div class="progressBg">
      <van-circle
        v-model="currentRate"
        :stroke-width="30"
        :rate="100"
        :speed="70"
        size="4rem"
        color="#1774FF"
        layer-color="none"
      >
      </van-circle>
      <img
        src="../assets/images/stateWating/progress_bg.png"
        class="progressImage"
      />
      <div class="progressLab">{{ currentRate }}%</div>
    </div>

    <div class="state-txt">
      <div class="flex-row">
        <div :class="step >= 1 ? 'txt-active' : ''">身份信息确认</div>
        <div :class="step >= 1 ? 'prepare-active' : 'prepare'"></div>
      </div>
      <div class="flex-row">
        <div :class="step >= 2 ? 'txt-active' : ''">正在提交资料</div>
        <div :class="step >= 2 ? 'prepare-active' : 'prepare'"></div>
      </div>
      <div class="flex-row">
        <div :class="step >= 3 ? 'txt-active' : ''">正在进行初审</div>
        <div :class="step >= 3 ? 'prepare-active' : 'prepare'"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "stateWatingModel7",
  data() {
    return {
      code: this.$route.query.code,
      persent: 0,
      step: 1,
      currentRate: 0,
      rate: 0,
      timer: null,
      second: 0,
      minute: 0,
    };
  },
  mounted() {
    this.initState();
    this.autoToHref();
  },
  methods: {
    initState() {
      var that = this;
      var intervalFunc = setInterval(function () {
        if (that.persent === 99) clearInterval(intervalFunc);
        that.persent++;
        that.modifyTxt(that.persent);
        that.persentWidth++;
      }, 1000 / 75);
    },
    autoToHref() {
      var that = this;
      setTimeout(function () {
        that.$router.push({
          name: "stagingAssessment_xyb",
          query: {
            source: that.$route.query.source,
          },
        });
      }, 2000);
    },

    modifyTxt(num) {
      switch (num) {
        case 0:
          this.step = 1;
          break;
        case 30:
          this.step = 2;
          break;
        case 100:
          this.step = 3;

          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#vue {
  height: 100vh;
  overflow: auto;
  background: url("../assets/images/stateWating/bg.png") no-repeat;
  background-size: cover;
  /* padding: 0.3rem 0.35rem; */
}
.progressBg {
  width: 100%;
  height: 4rem;
  text-align: center;
  margin-top: 1rem;
  position: absolute;
}

.progressImage {
  width: 4rem;
  height: 4rem;
  background: url("../assets/images/stateWating/progress_bg.png") no-repeat;
  background-size: cover;
  position: absolute;
  margin-left: -53%;
}
.progressLab {
  text-align: center;
  font-size: 14px;
  position: absolute;
  z-index: 10;
  margin-top: -22%;
  margin-left: 46.5%;
}

@keyframes spin {
  to {
    transform: rotateZ(360deg);
  }
}

.line-ani {
  position: relative;
  width: 80vw;
  top: 1.5rem;
  height: 2px;
  margin: 0 auto;
  border: 2px solid #ceddfc;
  border-radius: 1px;
}

.line-active {
  height: 2px;
  /* width: 20vw; */
  border: 2px solid #3b75f6;
  margin-top: -2.5px;
  /* animation: myfirst 4s 1; */
}

.pop {
  width: 0.5rem;
  height: 0.3rem;
  /* margin: 0 auto; */
  margin-top: -0.4rem;
  /* left: 20%; */
  background: url("../assets/images/stateWating/pop.png") no-repeat;
  background-size: cover;
  position: absolute;
  color: #ffffff;
  line-height: 0.3rem;
  font-size: 0.2rem;
  margin-left: -15px;
}

.state-txt {
  width: 80vw;
  position: relative;
  margin: 0 auto;
  top: 7rem;
}

.flex-row {
  display: flex;
  font-size: 0.3rem;
  margin-top: 25px;
  justify-content: space-between;
  color: #babdd0;
}

.txt-active {
  color: #000000;
}

.prepare {
  width: 0.32rem;
  height: 0.3rem;
  background: url("../assets/images/stateWating/prepare.png") no-repeat;
  background-size: cover;
}

.prepare-active {
  width: 0.32rem;
  height: 0.3rem;
  background: url("../assets/images/stateWating/prepare-active.png") no-repeat;
  background-size: cover;
}
</style>
